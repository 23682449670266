<template>
  <Modal :title="title" ref="modal" @clearInput="Reset()">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
           :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100"
           :style="{ width: uploadProgress + '%' }">{{ uploadProgress }}%
      </div>
    </div>
    <div class="mb-3">
      <label for="file-upload" class="form-label">Select file</label>
      <input class="form-control" type="file" :name="typeFile" id="file-upload" :accept="type"
             @change="update" ref="input">
    </div>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  name: "FileUpload",
  components: {Modal},
  props: ["title", "typeFile", "callback"],
  data() {
    return {
      uploadProgress: 0,
      file: null,
    }
  },
  computed: {
    type: {
      get() {
        switch (this.typeFile) {
          case "image":
            return "image/png, image/jpeg";

          case "video":
            return "video/mp4, video/mpeg, video/avi, video/ogg, video/x-msvideo, video/x-msvideo, video/x-flv, video/webm";

          case "audio":
            return "audio/mpeg, audio/aac, audio/mp4, audio/ogg";

          default :
            return "";
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    update(e) {
      let file = e.target.files[0]
      if (!file) {
        return;
      }
      let reader = new FileReader()

      reader.onload = e => {
        this.file = e.target.result
        this.FileInputChange(e)
      };
      reader.readAsDataURL(file)
    },


    Open: function () {
      this.$refs.modal.Open();
    },

    Reset: function () {
      this.$refs.input.value = "";
      this.uploadProgress = 0;
    },

    FileInputChange: function (event) {
      let form = {
        "mediaType": this.typeFile,
        "file": this.file
      }
      axios.post('/api/upload', form, {
        headers: {

        },
        onUploadProgress: (itemUpload) => {
          this.uploadProgress = parseInt(Math.round((itemUpload.loaded / itemUpload.total) * 100));
        }
      }).then(response => {
        setTimeout(() => {
          if (this.callback && typeof this.callback == "function") this.callback(response.data.url);
        }, 1000)
        this.Reset();
        this.Open();
      }).catch(error => {
        console.log(error.response);
      });
    }
  }
};
</script>

<style scoped>

</style>
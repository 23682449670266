import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (!token) {
    if(to.name === 'Terms' || to.name === 'Registration' || to.name === 'Login') {
      return next();
    } else {
      return next({name: 'Login', params: { referer: from.name }});
    }
  }
  if((to.name === 'Login' || to.name === 'Registration') && token) {

    return next({ name: from.name || 'Layouts' });
  }

  next();
});

export default router;
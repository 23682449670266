<template>
  <div class="container">
    <Header/>
    <main>
      <h5>All {{$route.params.mediaType[0].toUpperCase() + $route.params.mediaType.slice(1)}}</h5>
      <table v-if="isMediasLoad" class="table table-borderless">
        <thead>
        <tr>
          <td scope="col" class="table-col-chekbox">
            <div class="table-select">
              <Checkbox id="select-all" @change="SelectAllMedias"/>
            </div>
          </td>
          <th scope="col">
            <div class="table-name">NAME</div>
          </th>
          <th scope="col">
            <div class="table-time">MODIFIED</div>
          </th>
          <th scope="col">
            <div class="table-action">ACTIONS</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(media, index) in medias" :key="media.id">
          <td scope="row" class="table-col-chekbox">
            <div class="table-select">
              <input class="form-check-input" type="checkbox" :id="`select-${media.id}`" v-model="selectedMedias"
                     :value="`${ media.id }`">
              <label class="form-check-label" :for="`select-${media.id}`"></label>
            </div>
          </td>
          <td>
            <div class="table-name"><a href="#" @click.prevent="EditMedia($event, media.id)">{{ media.title }}</a></div>
          </td>
          <td>
            <div class="table-time">{{ media.updated_at }}</div>
          </td>
          <td class="td-action">
            <div class="table-action">
              <Dropdown :id="`${media.id}`" :edit="EditMedia" :del="DeleteMedias" ref="dropdown"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Loader v-else/>

      <div class="row g-0">
        <div class="col">
          <button class="btn btn-primary mb-3" @click.prevent="AddMedia">Add Media</button>
          <button class="btn btn-secondary mb-3" :disabled="isDeleteDisabled" @click.prevent="DeleteMedias">Delete
          </button>
        </div>
      </div>

      <Media
        title="Edit media"
        :media="selectedMedia != null ? this.medias.find(m => m.id == selectedMedia) : ''"
        :callback="UpdateMedia"
        ref="media"
        :isMediasLoad="isMediasLoad"/>

      <FileUpload
        title="Upload"
        :type-file="$route.params.mediaType"
        :callback="GetMedias"
        ref="fileUpload"/>

        <CreateText
          title="Insert Text"
          :callback="CreateMedia"
          ref="CreateText"/>

      <CreateTicker
        title="Insert Ticker"
        :callback="CreateMedia"
        ref="CreateTicker"/>

        <CreateWebPage
          title="Select Web Page"
          :callback="CreateMedia"
          ref="CreateWebPage"/>
    </main>
  </div>
</template>

<script>
import Header from "../components/Header";
import Dropdown from '../components/Dropdown.vue';
import Checkbox from '../components/Checkbox.vue';
import Loader from "../components/Loader.vue";
import Media from "../components/Modal.Media.vue";
import FileUpload from "../components/Modal.FileUpload.vue";
import CreateText from "../components/Modal.CreateText.vue";
import CreateTicker from "../components/Modal.CreateTicker.vue";
import CreateWebPage from "../components/Modal.CreateWebPage.vue";

export default {
  name: "Medias",
  components: {Header, Dropdown, Checkbox, Loader, Media, FileUpload, CreateText, CreateTicker, CreateWebPage},
  data() {
    return {
      medias: [],
      selectedMedias: [],
      selectedMedia: null,
      isMediasLoad: true
    }
  },

  computed: {
    isDeleteDisabled() {
      return this.selectedMedias.length > 0 ? false : true;
    }
  },

  mounted() {
    this.GetMedias();
  },

  methods: {
    GetMedias: function () {
      this.isMediasLoad = false;
      axios.get(`/api/media/${this.$route.params.mediaType}`).then(response => {
        this.medias = response.data;
        this.isMediasLoad = true;
      }).catch(error => {
        console.log(error.response);
      });
    },

    SelectAllMedias: function (checkbox) {
      if (checkbox.checked) {
        this.medias.forEach((media) => {
          this.selectedMedias.push(media.id);
        });
      } else {
        this.selectedMedias.splice(0);
      }
    },

    AddMedia: function () {
      if (this.$route.params.mediaType === 'video' || this.$route.params.mediaType === 'image') {
        this.$refs.fileUpload.Open();
      } else if (this.$route.params.mediaType === 'text') {
        this.$refs.CreateText.Open();
      } else if (this.$route.params.mediaType === 'ticker') {
        this.$refs.CreateTicker.Open();
      } else if (this.$route.params.mediaType === 'web') {
        this.$refs.CreateWebPage.Open();
      }

      // this.$router.push({name: "Create"});
    },

    CreateMedia() {
      this.isMediaLoad = false;
      axios.post(`/api/media/create/`, {
        type: this.$route.params.mediaType,
        text: this.text,
        ticker: this.ticker,
        web: this.web
      }).then(response => {
        console.log(response)
        this.GetMedias()
        this.isMediaLoad = true;
        this.text = ''
        this.ticker = ''
        this.web = ''
      }).catch(error => {
        console.log(error.response);
      });
    },

    DeleteMedias: function (event, ids = null) {
      let deletedMedias = [];
      if (ids != null) {
        deletedMedias.push(ids);
      } else {
        deletedMedias = Array.from(this.selectedMedias);
        this.selectedMedias.splice(0);
      }
      deletedMedias = deletedMedias.map(string => Number(string));
      axios.post("/api/media/delete/", deletedMedias).then(response => {
        this.GetMedias();
      }).catch(error => {
        console.log(error.response);
      });
    },

    EditMedia: function (event, id) {
      this.selectedMedia = id;
      this.$nextTick(function () {
        this.$refs.media.Open();
      });
    },

    UpdateMedia: function (media) {
      this.isMediasLoad = false;
      axios.post(`/api/media/update/${media.id}`, media).then(response => {
        // this.medias = response.data;
        this.$refs.media.Open();
        this.isMediasLoad = true;
      }).catch(error => {
        console.log(error.response);
      });
    }
  }
}
</script>

<style>

body {
  background-color: var(--white);
}

.navbar {
  background-color: var(--black);
}

.navbar-brand img {
  margin-right: 10px;
  width: 40px;
}

.dropdown-divider {
  margin: 0px;
}

main {
  padding: 100px 0px 50px !important;
}

.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
}

.table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.table tbody > tr {
  background-color: #fff;
  border-radius: 5px;
  height: 74px;
  max-height: 74px;
}

.table tbody > tr > td {
  vertical-align: middle;
}

.table thead {
  font-size: 11px;
  color: var(--gray);
}

.table thead .table-col-chekbox, .table tbody .table-col-chekbox {
  width: 80px;
  min-width: 60px;
  text-align: center;
  position: relative;
}

.table tbody .table-name {
  min-width: 40%;
  text-align: left;
  font-weight: bold;
}

.table tbody .table-time {
  min-width: 40%;
  text-align: left;
  font-weight: lighter;
}

.table tbody .td-action {
  text-align: center;
  width: 60px;
}

.table tbody .table-action {
  text-align: center;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

input[type="checkbox"] + label {
  justify-content: center;
}
</style>
<template>
    <router-view :user="user"/>
</template>

<script>

export default {
  name: "App",
  props: ['user'],
  data() {
    return {}
  },


  methods: {}
}
</script>

<template>
  <div class="col-7 editor-panel">
     <div class="layout-scale">
        <label class="layout-scale-label">Layout scale</label>
        <div class="btn" @click="minus">
          <i class="bi bi-dash-lg"></i>
        </div>
        <div>
          <input
            class="form-input"
            type="text" v-model="layout.scale" disabled>
        </div>
        <div class="btn" @click="plus">
          <i class="bi bi-plus-lg"></i>
        </div>
      </div>

      <button class="add editor-panel-item" @click.prevent.stop="$emit('showAddMedia')" @mousedown.stop>
        Insert
        <i class="bi bi-plus-circle"></i>
      </button>

      <button class="edit editor-panel-item" @click.prevent.stop="$emit('showEditMedia')" @mousedown.stop
              :disabled="selectedMedia == null">
        <i class="bi bi-pencil-square"></i>
      </button>

      <button class="delete editor-panel-item"
              @click.prevent.stop="RemoveMedia(this.selectedMedia)"
              @mousedown.stop
              :disabled="selectedMedia == null">
        <i class="bi bi-trash"></i>
      </button>
      <button type="button" class="btn btn-primary" @click.prevent="preview()"><i class="bi bi-display"></i> Preview
      </button>
  </div>
</template>

<script>
export default {
  name: "TopPanel",
  inject: ["RemoveMedia",],
  emits: ["showAddMedia", "showEditMedia"],
  props: ['callback', "selectedMedia", 'layout'],
  data() {
    return {
      redirect: false
    }
  },
  methods: {
    minus() {
      if (this.layout.scale > 1) {
        this.layout.scale--
          localStorage.setItem("LayoutScale", JSON.stringify(this.layout.scale))
      }
    },
    plus() {
      this.layout.scale++
        localStorage.setItem("LayoutScale", JSON.stringify(this.layout.scale))
    },

    preview() {
      this.callback(true)
    },
  }
}
</script>

<style scoped>
/* -------------------editor-panel-------------------- */
.layout-scale {
  display: contents;
}

.layout-scale-label {
  color: var(--accent-color-regular);
}

.editor-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.form-input {
  text-align: center;
  align-items: center;
  width: 50px;
}

.editor-panel-item {
  padding: 7px 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

.editor-panel-item i:before {
  font-size: 20px;
  color: var(--gray);
  position: relative;
  top: 4px;
}

.editor-panel-item:hover:not(:disabled) i:before {
  color: var(--accent-color-regular);
}

.editor-panel-item:disabled i:before {
  color: var(--gray-light);
}

.editor-panel-item:active:not(:disabled) {
  transform: scale(0.95);
  color: var(--accent-color-regular);
}

.editor-panel-item.add {
margin-left: 25px;
}

.editor-panel-item.add, .editor-panel-item.add i:before {
  color: var(--accent-color-regular);
}

/* -------------------editor-panel-------------------- */
</style>

<template>
  <Modal :title="title" ref="modal">
    <div v-if="isLayoutLoad" :style="{  paddingBottom: geocodingData.length * 44 + 'px' }">
      <div class="mb-3">
        <label for="time-zone" class="form-label">Time Zone</label>
        <div class="mb-3">
          <select class="form-select" id="time-zone" v-model="mediaData.selectedTimeZone"
                  @change="changeSelectedTimeZone">
            <option disabled value="null">Choose Time Zone</option>
            <option v-for="timeZone in timeZonesDatabase" :value="timeZone">{{ timeZone.text }}</option>
          </select>
          <span class="text-danger" v-if="errors.selectedTimeZone">
                  <span>{{ errors.selectedTimeZone }}</span>
              </span>
        </div>

        <div>
          <select class="form-select" id="time-zone-utc" v-model="mediaData.selectedTimeZoneUtc"
                  @change="changeSelectedTimeZoneUtc">
            <option disabled value="null">Choose UTC</option>
            <option v-if="mediaData.selectedTimeZone" v-for="(utc) in mediaData.selectedTimeZone.utc" :value="utc">{{
                utc
              }}
            </option>
          </select>
          <span class="text-danger" v-if="errors.selectedTimeZoneUtc">
                  <span>{{ errors.selectedTimeZoneUtc }}</span>
              </span>
        </div>
      </div>

      <div class="dropdown mb-3">
        <label for="postal-code" class="form-label">{{ formatted ? 'Location' : 'Enter Postal Code and/or Address' }}</label>
        <input class="form-control" type="text" name="postalCode" id="postal-code" v-model="mediaData.postalCode"
               ref="input">
        <span class="text-danger" v-if="errors.postalCode">
                  <span>{{ errors.postalCode }}</span>
              </span>
        <div class="text-danger" v-if="errors.formatted">
          <span>{{ errors.formatted }}</span>
        </div>
        <ul v-if="geocodingData.length" class="dropdown-menu" :class="{show: geocodingData.length}">
          <li class="dropdown-item cursor-pointer" v-for="data in geocodingData"
              @click.prevent.stop="chooseLocation(data.properties.formatted)">
            <span>{{ data.properties.address_line2 }}</span></li>
        </ul>
      </div>

      <div class="mb-3">
        <div class="form-label">Choose Date</div>
        <div class="choose-date">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="date" id="dateToday" value="today"
                   v-model="daySelection">
            <label class="form-check-label" for="dateToday">Today</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="date" id="dateTomorrow" value="tomorrow"
                   v-model="daySelection">
            <label class="form-check-label" for="dateTomorrow">Tomorrow</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="date" id="dateSaturday" value="saturday"
                   v-model="daySelection">
            <label class="form-check-label" for="dateSaturday">Saturday</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="date" id="dateEnterDate" value="datePicker"
                   v-model="daySelection">
          </div>
          <Datepicker v-model="datePicker" placeholder="Choose Date" :enableTimePicker="false"
                      format="MM/dd/yyyy" :disabled="daySelection !== 'datePicker'"></Datepicker>
        </div>
        <span class="text-danger" v-if="errors.date">
                  <span>{{ errors.date }}</span>
              </span>
      </div>

      <div class="mb-3">
        <div class="form-label">Choose Language</div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="lang" id="langEnglish" value="english"
                 v-model="mediaData.lang">
          <label class="form-check-label" for="langEnglish">English</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="lang" id="langHebrew" value="hebrew"
                 v-model="mediaData.lang">
          <label class="form-check-label" for="langHebrew">Hebrew</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="lang" id="langEnglishAndHebrew" value="englishAndHebrew"
                 v-model="mediaData.lang">
          <label class="form-check-label" for="langEnglishAndHebrew">English&Hebrew</label>
        </div>
      </div>
    </div>

    <Loader v-else/>

    <template v-slot:footer>
      <button type="button" class="btn btn-primary pull-right" @click.prevent="validation()">Insert media</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import timeZonesDatabase from "../mixsins/timeZonesDatabase";
import Loader from "../components/Loader.vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import {Field, Form, ErrorMessage} from 'vee-validate';
// import * as yup from 'yup';
import moment from 'moment'

export default {
  name: "Modal.CreateZmanim",
  components: { Modal, Loader, Datepicker },
  mixins: [timeZonesDatabase],
  props: ["title", "callback"],
  data() {
    return {
      datePicker: null,
      daySelection: 'today',
      isLayoutLoad: true,
      geocodingData: [],
      errors: {},
      formatted: null,
      mediaData: {
        lang: 'englishAndHebrew',
        daySelection: 'today',
        date: moment().format('YYYY-MM-DD'),
        postalCode: '',
        selectedTimeZone: null,
        selectedTimeZoneUtc: null,
        latitude: '',
        longitude: '',
        city: '',
        state: '',
        zmanimData: [],
      },
        options: {}
    }
  },

  watch: {
    datePicker() {
      if (!this.datePicker) {
        this.mediaData.date = null
      } else {
        this.mediaData.date = moment(this.datePicker).format('YYYY-MM-DD')
        this.mediaData.daySelection = this.daySelection
        this.errors.date = ''
      }
    },

    daySelection() {
      if (this.daySelection === 'today') {
        // this.mediaData.date = moment().format('MM/DD/YYYY')
        this.mediaData.date = moment().format('YYYY-MM-DD')
        this.mediaData.daySelection = this.daySelection
      } else if (this.daySelection === 'tomorrow') {
        let now = new Date()
        this.mediaData.date = moment(now.setDate(now.getDate() + 1)).format('YYYY-MM-DD')
        this.mediaData.daySelection = this.daySelection
      } else if (this.daySelection === 'saturday') {
        let now = new Date()
        let dif = 6 - now.getDay()
        this.mediaData.date = moment(now.setDate(now.getDate() + dif)).format('YYYY-MM-DD')
        this.mediaData.daySelection = this.daySelection
      } else {
        if (this.datePicker) {
          this.mediaData.date = moment(this.datePicker).format('YYYY-MM-DD')
          this.mediaData.daySelection = this.daySelection
        } else {
          this.mediaData.date = null
          this.mediaData.daySelection = null
        }

      }
    },

    'mediaData.postalCode': {
      deep: true,
      handler() {
        this.geocodingData = []
        if (this.mediaData.postalCode) {
          let config = {
            method: 'get',
          };
          fetch(`https://api.geoapify.com/v1/geocode/search?text=${this.mediaData.postalCode}&apiKey=8d010b61b86347f1a28662dffedb08d6`, config)
            .then(response => response.json())
            .then(result => {
              if (!result.features.length && this.mediaData.postalCode.length < 3 ) {
                Object.assign(this.errors, {
                  postalCode: 'Enter at least 3 characters'
                })
              } else if (!result.features.length && this.mediaData.postalCode.length >= 3) {
                this.geocodingData.push({properties: {address_line2: 'No results were found for this query.'}})
              } else if (result.features.length && result.features[0].properties.formatted === this.mediaData.postalCode) {
                console.log(this.mediaData)
              } else {
                delete this.errors["postalCode"];
                delete this.errors["formatted"];
                this.geocodingData = result.features
                this.mediaData.latitude = result.features[0].properties.lat
                this.mediaData.longitude = result.features[0].properties.lon
                this.mediaData.city = result.features[0].properties.city ?? null
                this.mediaData.state = result.features[0].properties.state ?? null
                this.mediaData.country = result.features[0].properties.country ?? null
              }
            })
            .catch(error => {
              console.log('error', error)
            });
        }
      }
    }
  },
  mounted() {
    // this.selectedTimeZoneUtc = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    chooseLocation(data) {
      this.mediaData.postalCode = data
      this.formatted = data
      this.geocodingData = []
    },
    changeSelectedTimeZone() {
      this.selectedTimeZoneUtc = null
      this.errors.selectedTimeZone = ''
    },

    changeSelectedTimeZoneUtc() {
      this.errors.selectedTimeZoneUtc = ''
    },

    Open: function () {
      this.$refs.modal.Open();
      this.mediaData.lang = 'englishAndHebrew';
      this.datePicker = null;
      this.daySelection = 'today',
        // this.mediaData.date = null;
      this.mediaData.postalCode = '';
      this.mediaData.selectedTimeZone = null;
      this.mediaData.selectedTimeZoneUtc = null;
      this.mediaData.latitude = '';
      this.mediaData.longitude = '';
      this.mediaData.city = '';
      this.mediaData.state = '';
      this.mediaData.zmanimData = [];
    },

    async addMedia() {
        let config = {
            method: 'get',
        };
        await fetch(`https://www.hebcal.com/zmanim?cfg=json&latitude=${this.mediaData.latitude}&longitude=${this.mediaData.longitude}&tzid=${this.mediaData.selectedTimeZoneUtc}&date=${this.mediaData.date}`, config)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                console.log(moment(result.times.alotHaShachar.slice(0, 19)).format('hh:mm a'))
                  this.mediaData.zmanimData = [
                    {titleEnglish: 'Alos', date: moment(result.times.alotHaShachar.slice(0, 19)).format('hh:mm a'), titleHebrew: 'עלות השחר'},
                    {titleEnglish: 'Misheyakir', date: moment(result.times.misheyakir.slice(0, 19)).format('hh:mm a'), titleHebrew: 'משיכיר'},
                    {titleEnglish: 'Sunrise', date: moment(result.times.sunrise.slice(0, 19)).format('hh:mm a'), titleHebrew: 'הנץ החמה'},
                    {
                      titleEnglish: 'Latest shema (MA)',
                      date: moment(result.times.sofZmanShmaMGA.slice(0, 19)).format('hh:mm a'),
                      titleHebrew: 'סוף זמן קריאת שמע (מ"א)'
                    },
                    {titleEnglish: 'Latest shema (GRA)', date: moment(result.times.sofZmanShma.slice(0, 19)).format('hh:mm a'), titleHebrew: 'סוף זמן קריאת שמע (גר"א)'},
                    {
                      titleEnglish: 'Latest shachris (GRA)',
                      date: moment(result.times.sofZmanTfilla.slice(0, 19)).format('hh:mm a'),
                      titleHebrew: 'סוף זמן תפילה (גר"א)'
                    },
                    {titleEnglish: 'Chatzos', date: moment(result.times.chatzot.slice(0, 19)).format('hh:mm a'), titleHebrew: 'חצות'},
                    {titleEnglish: 'Mincha Gedolah', date: moment(result.times.minchaGedola.slice(0, 19)).format('hh:mm a'), titleHebrew: 'מנחה גדולה'},
                    {titleEnglish: 'Plag Hamincha', date: moment(result.times.plagHaMincha.slice(0, 19)).format('hh:mm a'), titleHebrew: 'פלג המנחה'},
                    {titleEnglish: 'Candle Lighting ', date: moment(result.times.sunset.slice(0, 19)).subtract(18, 'minutes').format('hh:mm a'), titleHebrew: 'הדלקת נרות'},
                    {titleEnglish: 'Shkia', date: moment(result.times.sunset.slice(0, 19)).format('hh:mm a'), titleHebrew: 'שקיעת החמה'},
                    {titleEnglish: 'Tzais', date: moment(result.times.tzeit85deg.slice(0, 19)).format('hh:mm a'), titleHebrew: 'צאת כוכבים'},
                    {titleEnglish: 'Tzais Rabbeinu Tam', date: moment(result.times.tzeit72min.slice(0, 19)).format('hh:mm a'), titleHebrew: 'צאת הכוכבים רבנו תם'},
                  ]
                this.isLayoutLoad = true
            })
        .catch(error => {
        console.log(error.response);
        this.isLayoutLoad = true
      });

      if (this.callback && typeof this.callback == "function" && !this.formatted) {
        Object.assign(this.errors, {
          formatted: 'Сhoose a location',
        })
      }

      if (this.callback && typeof this.callback == "function" && this.formatted) {
        await this.callback(this.mediaData);
        await this.$refs.modal.Open();

        this.mediaData.lang = 'englishAndHebrew';
        this.datePicker = null;
        this.daySelection = 'today';
          // this.mediaData.date = null;
          this.mediaData.postalCode = '';
        this.mediaData.selectedTimeZone = null;
        this.mediaData.selectedTimeZoneUtc = null;
        this.mediaData.latitude = '';
        this.mediaData.longitude = '';
        this.mediaData.city = '';
        this.mediaData.state = '';
        this.mediaData.zmanimData = [];
      }
    },

    validation() {
      this.errors = {}
      if (!this.mediaData.selectedTimeZone) {
        Object.assign(this.errors, {
          selectedTimeZone: 'Choose Time Zone',
        })
      }
      if (!this.mediaData.selectedTimeZoneUtc) {
        Object.assign(this.errors, {
          selectedTimeZoneUtc: 'Choose UTC',
        })
      }
      if (!this.mediaData.postalCode) {
        Object.assign(this.errors, {
          postalCode: 'Enter Postal Code',
        })
      }
      if (!this.mediaData.date) {
        Object.assign(this.errors, {
          date: 'Choose date',
        })
      }
      if (this.isEmptyObject(this.errors)) {
        this.addMedia()
      }
    },

    isEmptyObject(obj) {
      for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
          return false;
        }
      }
      return true;
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.choose-date {
  display: flex;
  align-items: center;
}

</style>

<template>
  <Modal :title="title" size="large" ref="modal">
    <FileUpload
      title="Upload"
      :type-file="typeFile"
      :callback="GetMedia"
      ref="fileUpload"/>

    <button class="btn btn-primary mb-3" v-if="typeFile === 'video' || typeFile === 'image'" @click.prevent="this.$refs.fileUpload.Open">Upload a File to add to the database</button>
    <div class="mb-3" v-if="typeFile === 'text'">
      <label for="textarea-media-text" class="form-label">Insert Text to add to the database</label>
      <textarea class="form-control mb-3" name="webPage" id="textarea-media-text" v-model="text"  ref="textarea"></textarea>
      <button type="button" class="btn btn-primary ml-2" :disabled="!text" @click.prevent="CreateMedia()">Add media in Database
      </button>
    </div>
    <div class="mb-3" v-if="typeFile === 'ticker'">
      <label for="textarea-media-ticker" class="form-label">Insert Ticker  to add to the database</label>
      <textarea class="form-control mb-3" name="webPage" id="textarea-media-ticker" v-model="ticker"  ref="textarea"></textarea>
      <button type="button" class="btn btn-primary ml-2" :disabled="!ticker" @click.prevent="CreateMedia()">Add media in Database
      </button>
    </div>
    <div class="mb-3" v-if="typeFile === 'web'">
      <label for="input-media-web" class="form-label">Insert Link to add to the database</label>
      <input class="form-control mb-3" type="text" name="webPage" id="input-media-web" v-model="web"  ref="input">
      <button type="button" class="btn btn-primary ml-2" :disabled="!web" @click.prevent="CreateMedia()">Add media in Database
      </button>
    </div>


      <div class="mb-3">Select Media to add to your layout</div>
    <div v-if="isMediaLoad" class="items-video">
      <div v-for="(mediaItem, mediaIndex) in media"
           :key="mediaIndex" @click.prevent="selectedIndex = mediaIndex">

        <div v-if="mediaItem.type === 'image' || mediaItem.type === 'video'" :class="['item-video', { selected: selectedIndex === mediaIndex }]">
          <div :class="{preview: mediaItem.type === 'image' || mediaItem.type === 'video'}">
            <img v-if="mediaItem.type === 'image'" :src="mediaItem.url"  class="img-fluid"/>

            <video v-if="mediaItem.type === 'video'" type="video" :alt="mediaItem.title">
              <source :src="mediaItem.url" type="video/mp4">
              <source :src="mediaItem.url" type="video/ogg">
              Your browser does not support the video tag.
            </video>

            <button type="button" class="btn-close btn-close-media" aria-label="Close" @click.prevent="DeleteMedia(mediaItem.id)"></button>
          </div>
          <span class="title">{{ mediaItem.title }}</span>
        </div>

      </div>
    </div>

    <div v-if="isMediaLoad" class="items-text">
      <div v-for="(mediaItem, mediaIndex) in media"
           :key="mediaIndex" @click.prevent="selectedIndex = mediaIndex">

        <div v-if="mediaItem.type === 'text' || mediaItem.type === 'ticker'" class="mb-3" :class="['item-text', { selected: selectedIndex === mediaIndex }]">
          <div :class="{preview: mediaItem.type === 'text' || mediaItem.type === 'ticker'}">

            <div v-if="mediaItem.type === 'text'">{{mediaItem.text}}</div>

            <div v-if="mediaItem.type === 'ticker'">{{mediaItem.ticker}}</div>

            <button type="button" class="btn-close btn-close-media" aria-label="Close" @click.prevent="DeleteMedia(mediaItem.id)"></button>
          </div>
        </div>

      </div>
    </div>

    <div v-if="isMediaLoad"  class="items-web">
      <div v-for="(mediaItem, mediaIndex) in media"
           :key="mediaIndex" @click.prevent="selectedIndex = mediaIndex">

        <div v-if="mediaItem.type === 'web'" :class="['item-web', { selected: selectedIndex === mediaIndex }]">
          <div :class="{preview: mediaItem.type === 'web'}">

            <iframe :src="mediaItem.web" frameborder="0"></iframe>

            <button type="button" class="btn-close btn-close-media" aria-label="Close" @click.prevent="DeleteMedia(mediaItem.id)"></button>
          </div>
          <span class="title">{{ mediaItem.title }}</span>
        </div>

      </div>

    </div>

    <Loader v-else/>

    <template v-slot:footer>
      <button type="button" class="btn btn-primary" :disabled="selectedIndex == null"
              @click.prevent="SelectMedia(media[selectedIndex])">Insert media
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import Loader from "./Loader.vue";
import FileUpload from "./Modal.FileUpload.vue";

export default {
  name: "OutputMedia",
  components: {Modal, Loader, FileUpload},
  props: ["title", "typeFile", "callback"],
  inject: ["AddMedia"],
  data() {
    return {
      media: [],
      isMediaLoad: false,
      isButtonDisabled: false,
      selectedIndex: null,
      text: '',
      ticker: '',
      web: '',

    }
  },

  computed: {
    type: {
      get() {
        switch (this.typeFile) {
          case "image":
            return "image/png, image/jpeg";

          case "video":
            return "video/mp4, video/avi, video/mpeg, video/x-msvideo";

          default :
            return "";
        }
      }
    },
  },

  mounted() {
  },

  methods: {
    GetMedia: function () {
      this.isMediaLoad = false;
      axios.get(`/api/media/${this.typeFile}`).then(response => {
        this.media = response.data;
        this.isMediaLoad = true;
      }).catch(error => {
        console.log(error.response);
      });
    },

    Open: function () {
      this.$refs.modal.Open();
      this.GetMedia();
    },

    SelectMedia: function (media) {
      if (this.callback && typeof this.callback == "function") this.callback(media);
      this.$refs.modal.Open();
    },

    CreateMedia() {
      this.isMediaLoad = false;
      axios.post(`/api/media/create/`, {
        type: this.typeFile,
        text: this.text,
        ticker: this.ticker,
        web: this.web
      }).then(response => {
        this.GetMedia()
        this.isMediaLoad = true;
        this.text = ''
        this.ticker = ''
        this.web = ''
      }).catch(error => {
        console.log(error.response);
      });
    },

    DeleteMedia: function (id) {
      this.isMediaLoad = false;
      axios.post(`/api/media/delete/`, {id: id}).then(response => {
        this.GetMedia()
        this.isMediaLoad = true;
      }).catch(error => {
        console.log(error.response);
      });
    }
  }
};
</script>

<style scoped>
.inline-block {
  display: inline-block;
  width: auto;
}

.items-video {
  display: flex;
  flex-wrap: wrap;
}

.item-video {
  flex: 1 1 120px;
  min-width: 120px;
  max-width: 160px;
  margin: 0 10px 10px 0;
  transition: all 0.2s ease-in-out;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
}

.items-web {
  display: flex;
  flex-wrap: wrap;
}

.item-web {
  margin: 0 10px 10px 0;
  transition: all 0.2s ease-in-out;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
}

.item-text {
  position: relative;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  /*background-color: #D7EFEA;*/
  background-color: #E3EEEB;
}

.item-text.selected, .item-video.selected, .item-web.selected {
  border-color: var(--accent-color-light);
}

.item-video:hover, .item-web:hover {
  transform: scale(1.05);
}

.item-text:hover {
  transform: scale(1.01);
}

.item-video .preview {
  /*width: 160px;*/
  height: 120px;
  overflow: hidden;
}

.item-video .preview img, .item-video .preview video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.item-text .preview {
padding: 5px 25px 5px 10px;
}

.btn-close-media {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
}

.item-video .title {
  word-wrap: break-word;
}
</style>

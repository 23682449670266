export default {
  data() {
    return {
      fontFamilyDatabase: [
        { value: "Arial", label: "Arial" },
        { value: "Arial Italic", label: "Arial Italic" },
        { value: "Arial Bold", label: "Arial Bold" },
        { value: "Arial Bold Italic", label: "Arial Bold Italic" },
        { value: "Arial Black", label: "Arial Black" },
        { value: "Bahnschrift", label: "Bahnschrift" },
        { value: "Calibri Light", label: "Calibri Light" },
        { value: "Calibri Light Italic", label: "Calibri Light Italic" },
        { value: "Calibri", label: "Calibri" },
        { value: "Calibri Italic", label: "Calibri Italic" },
        { value: "Calibri Bold", label: "Calibri Bold" },
        { value: "Calibri Bold Italic", label: "Calibri Bold Italic" },
        { value: "Cambria", label: "Cambria" },
        { value: "Cambria Italic", label: "Cambria Italic" },
        { value: "Cambria Bold", label: "Cambria Bold" },
        { value: "Cambria Bold Italic", label: "Cambria Bold Italic" },
        { value: "Cambria Math", label: "Cambria Math" },
        { value: "Candara", label: "Candara" },
        { value: "Candara Italic", label: "Candara Italic" },
        { value: "Candara Bold", label: "Candara Bold" },
        { value: "Candara Bold Italic", label: "Candara Bold Italic" },
        { value: "Comic Sans MS", label: "Comic Sans MS" },
        { value: "Comic Sans MS Italic", label: "Comic Sans MS Italic" },
        { value: "Comic Sans MS Bold", label: "Comic Sans MS Bold" },
        { value: "Comic Sans MS Bold Italic", label: "Comic Sans MS Bold Italic" },
        { value: "Consolas", label: "Consolas" },
        { value: "Consolas Italic", label: "Consolas Italic" },
        { value: "Consolas Bold", label: "Consolas Bold" },
        { value: "Consolas Bold Italic", label: "Consolas Bold Italic" },
        { value: "Constantia", label: "Constantia" },
        { value: "Constantia Italic", label: "Constantia Italic" },
        { value: "Constantia Bold", label: "Constantia Bold" },
        { value: "Constantia Bold Italic", label: "Constantia Bold Italic" },
        { value: "Corbel", label: "Corbel" },
        { value: "Corbel Italic", label: "Corbel Italic" },
        { value: "Corbel Bold", label: "Corbel Bold" },
        { value: "Corbel Bold Italic", label: "Corbel Bold Italic" },
        { value: "Courier New", label: "Courier New" },
        { value: "Courier New Italic", label: "Courier New Italic" },
        { value: "Courier New Bold", label: "Courier New Bold" },
        { value: "Courier New Bold Italic", label: "Courier New Bold Italic" },
        { value: "Ebrima", label: "Ebrima" },
        { value: "Ebrima Bold", label: "Ebrima Bold" },
        { value: "Franklin Gothic Medium", label: "Franklin Gothic Medium" },
        { value: "Franklin Gothic Medium Italic", label: "Franklin Gothic Medium Italic" },
        { value: "Gabriola", label: "Gabriola" },
        { value: "Gadugi", label: "Gadugi" },
        { value: "Gadugi Bold", label: "Gadugi Bold" },
        { value: "Georgia", label: "Georgia" },
        { value: "Georgia Italic", label: "Georgia Italic" },
        { value: "Georgia Bold", label: "Georgia Bold" },
        { value: "Georgia Bold Italic", label: "Georgia Bold Italic" },
        { value: "Impact", label: "Impact" },
        { value: "Ink Free", label: "Ink Free" },
        { value: "Javanese Text", label: "Javanese Text" },
        { value: "Lucida Console", label: "Lucida Console" },
        { value: "Lucida Sans Unicode", label: "Lucida Sans Unicode" },
        { value: "Malgun Gothic", label: "Malgun Gothic" },
        { value: "Malgun Gothic Bold", label: "Malgun Gothic Bold" },
        { value: "Malgun Gothic Semilight", label: "Malgun Gothic Semilight" },
        { value: "Marlett", label: "Marlett" },
        { value: "Microsoft Himalaya", label: "Microsoft Himalaya" },
        { value: "Microsoft JhengHei Light", label: "Microsoft JhengHei Light" },
        { value: "Microsoft JhengHei", label: "Microsoft JhengHei" },
        { value: "Microsoft JhengHei Bold", label: "Microsoft JhengHei Bold" },
        { value: "Microsoft JhengHei UI Light", label: "Microsoft JhengHei UI Light" },
        { value: "Microsoft JhengHei UI", label: "Microsoft JhengHei UI" },
        { value: "Microsoft JhengHei UI Bold", label: "Microsoft JhengHei UI Bold" },
        { value: "Microsoft New Tai Lue", label: "Microsoft New Tai Lue" },
        { value: "Microsoft New Tai Lue Bold", label: "Microsoft New Tai Lue Bold" },
        { value: "Microsoft PhagsPa", label: "Microsoft PhagsPa" },
        { value: "Microsoft PhagsPa Bold", label: "Microsoft PhagsPa Bold" },
        { value: "Microsoft Sans Serif", label: "Microsoft Sans Serif" },
        { value: "Microsoft Tai Le", label: "Microsoft Tai Le" },
        { value: "Microsoft Tai Le Bold", label: "Microsoft Tai Le Bold" },
        { value: "Microsoft YaHei Light", label: "Microsoft YaHei Light" },
        { value: "Microsoft YaHei", label: "Microsoft YaHei" },
        { value: "Microsoft YaHei Bold", label: "Microsoft YaHei Bold" },
        { value: "Microsoft YaHei UI Light", label: "Microsoft YaHei UI Light" },
        { value: "Microsoft YaHei UI", label: "Microsoft YaHei UI" },
        { value: "Microsoft YaHei UI Bold", label: "Microsoft YaHei UI Bold" },
        { value: "Microsoft Yi Baiti", label: "Microsoft Yi Baiti" },
        { value: "Mongolian Baiti", label: "Mongolian Baiti" },
        { value: "MV Boli", label: "MV Boli" },
        { value: "Myanmar Text", label: "Myanmar Text" },
        { value: "Myanmar Text Bold", label: "Myanmar Text Bold" },
        { value: "Nirmala UI Semilight", label: "Nirmala UI Semilight" },
        { value: "Nirmala UI", label: "Nirmala UI" },
        { value: "Nirmala UI Bold", label: "Nirmala UI Bold" },
        { value: "Palatino Linotype", label: "Palatino Linotype" },
        { value: "Palatino Linotype Italic", label: "Palatino Linotype Italic" },
        { value: "Palatino Linotype Bold", label: "Palatino Linotype Bold" },
        { value: "Palatino Linotype Bold Italic", label: "Palatino Linotype Bold Italic" },
        { value: "Segoe MDL2 Assets", label: "Segoe MDL2 Assets" },
        { value: "Segoe Print", label: "Segoe Print" },
        { value: "Segoe Print Bold", label: "Segoe Print Bold" },
        { value: "Segoe Script", label: "Segoe Script" },
        { value: "Segoe Script Bold", label: "Segoe Script Bold" },
        { value: "Segoe UI Light", label: "Segoe UI Light" },
        { value: "Segoe UI Light Italic", label: "Segoe UI Light Italic" },
        { value: "Segoe UI Semilight", label: "Segoe UI Semilight" },
        { value: "Segoe UI Semilight Italic", label: "Segoe UI Semilight Italic" },
        { value: "Segoe UI", label: "Segoe UI" },
        { value: "Segoe UI Italic", label: "Segoe UI Italic" },
        { value: "Segoe UI Semibold", label: "Segoe UI Semibold" },
        { value: "Segoe UI Semibold Italic", label: "Segoe UI Semibold Italic" },
        { value: "Segoe UI Bold", label: "Segoe UI Bold" },
        { value: "Segoe UI Bold Italic", label: "Segoe UI Bold Italic" },
        { value: "Segoe UI Black", label: "Segoe UI Black" },
        { value: "Segoe UI Black Italic", label: "Segoe UI Black Italic" },
        { value: "Segoe UI Historic", label: "Segoe UI Historic" },
        { value: "Segoe UI Emoji", label: "Segoe UI Emoji" },
        { value: "Segoe UI Symbol", label: "Segoe UI Symbol" },
        { value: "SimSun", label: "SimSun" },
        { value: "NSimSun", label: "NSimSun" },
        { value: "SimSun-ExtB", label: "SimSun-ExtB" },
        { value: "Sitka Small", label: "Sitka Small" },
        { value: "Sitka Small Italic", label: "Sitka Small Italic" },
        { value: "Sitka Small Bold", label: "Sitka Small Bold" },
        { value: "Sitka Small Bold Italic", label: "Sitka Small Bold Italic" },
        { value: "Sitka Text", label: "Sitka Text" },
        { value: "Sitka Text Italic", label: "Sitka Text Italic" },
        { value: "Sitka Text Bold", label: "Sitka Text Bold" },
        { value: "Sitka Text Bold Italic", label: "Sitka Text Bold Italic" },
        { value: "Sitka Subheading", label: "Sitka Subheading" },
        { value: "Sitka Subheading Italic", label: "Sitka Subheading Italic" },
        { value: "Sitka Subheading Bold", label: "Sitka Subheading Bold" },
        { value: "Sitka Subheading Bold Italic", label: "Sitka Subheading Bold Italic" },
        { value: "Sitka Heading", label: "Sitka Heading" },
        { value: "Sitka Heading Italic", label: "Sitka Heading Italic" },
        { value: "Sitka Heading Bold", label: "Sitka Heading Bold" },
        { value: "Sitka Heading Bold Italic", label: "Sitka Heading Bold Italic" },
        { value: "Sitka Display", label: "Sitka Display" },
        { value: "Sitka Display Italic", label: "Sitka Display Italic" },
        { value: "Sitka Display Bold", label: "Sitka Display Bold" },
        { value: "Sitka Display Bold Italic", label: "Sitka Display Bold Italic" },
        { value: "Sitka Banner", label: "Sitka Banner" },
        { value: "Sitka Banner Italic", label: "Sitka Banner Italic" },
        { value: "Sitka Banner Bold", label: "Sitka Banner Bold" },
        { value: "Sitka Banner Bold Italic", label: "Sitka Banner Bold Italic" },
        { value: "Sylfaen", label: "Sylfaen" },
        { value: "Symbol", label: "Symbol" },
        { value: "Tahoma", label: "Tahoma" },
        { value: "Tahoma Bold", label: "Tahoma Bold" },
        { value: "Times New Roman", label: "Times New Roman" },
        { value: "Times New Roman Italic", label: "Times New Roman Italic" },
        { value: "Times New Roman Bold", label: "Times New Roman Bold" },
        { value: "Times New Roman Bold Italic", label: "Times New Roman Bold Italic" },
        { value: "Trebuchet MS", label: "Trebuchet MS" },
        { value: "Trebuchet MS Italic", label: "Trebuchet MS Italic" },
        { value: "Trebuchet MS Bold", label: "Trebuchet MS Bold" },
        { value: "Trebuchet MS Bold Italic", label: "Trebuchet MS Bold Italic" },
        { value: "Verdana", label: "Verdana" },
        { value: "Verdana Italic", label: "Verdana Italic" },
        { value: "Verdana Bold", label: "Verdana Bold" },
        { value: "Verdana Bold Italic", label: "Verdana Bold Italic" },
        { value: "Webdings", label: "Webdings" },
        { value: "Wingdings", label: "Wingdings" },
        { value: "Yu Gothic Light", label: "Yu Gothic Light" },
        { value: "Yu Gothic Regular", label: "Yu Gothic Regular" },
        { value: "Yu Gothic Medium", label: "Yu Gothic Medium" },
        { value: "Yu Gothic Bold", label: "Yu Gothic Bold" },
        { value: "Yu Gothic UI Light", label: "Yu Gothic UI Light" },
        { value: "Yu Gothic UI Semilight", label: "Yu Gothic UI Semilight" },
        { value: "Yu Gothic UI Regular", label: "Yu Gothic UI Regular" },
        { value: "Yu Gothic UI Semibold", label: "Yu Gothic UI Semibold" },
        { value: "Yu Gothic UI Bold", label: "Yu Gothic UI Bold" },
      ]

    }
  }
}
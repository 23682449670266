<template>
  <Modal :title="title" ref="modal">

    <div class="mb-3">
      <label for="layout-background-image" class="form-label">Insert Text</label>
      <textarea class="form-control" name="webPage" id="layout-background-image" v-model="text"  ref="input"></textarea>
    </div>

    <template v-slot:footer>
      <button type="button" class="btn btn-primary" @click.prevent="SelectMedia(text)">Insert media</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
export default {
  name: "Modal.CreateText",
  components: { Modal },
  props: ["title", "callback"],
  data() {
    return {
      text: '',
    }
  },
  methods: {
    Open: function() {
      this.$refs.modal.Open();
    },
    SelectMedia: function(media) {
      if(this.callback && typeof this.callback == "function") this.callback(media);
      this.$refs.modal.Open();
      this.text = ''
    },

  }
};
</script>
<template>
  <div class="container">
    <div class="row g-0">
      <div class="col-12 col-md-7 background-left">

      </div>
      <div class="col-12 col-md-5 form align-self-center">
        <form @submit.prevent="registration">
          <div class="app-name">Zmanim</div>
          <h1>Create an account</h1>
          <div class="row mb-3">
            <label for="name" class="form-label">Name*</label>

            <div class="col-md">
              <input id="name" type="text" class="form-control" :class="{'input-validation-error': errors.name}" name="name" v-model="name" autocomplete="name"
                     autofocus>

              <span class="text-danger" v-if="errors.name">
                  <span>{{ errors.name[0] }}</span>
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <label for="email" class="form-label">Email Address*</label>

            <div class="col-md">
              <input id="email" type="email" class="form-control" :class="{'input-validation-error': errors.email}" name="email" v-model="email" autocomplete="email">

              <span class="text-danger" v-if="errors.email">
                  <span>{{ errors.email[0] }}</span>
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <label for="password" class="form-label">Password*</label>

            <div class="col-md">
              <input id="password" type="password" class="form-control" :class="{'input-validation-error': errors.password}" name="password" v-model="password"
                     autocomplete="new-password">

              <span class="text-danger" v-if="errors.password">
                  <span>{{ errors.password[0] }}</span>
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <label for="password-confirm" class="col-form-label">Confirm Password*</label>

            <div class="col-md">
              <input id="password-confirm" type="password" class="form-control" :class="{'input-validation-error': errors.password}" name="password_confirmation"
                     v-model="passwordConfirm" autocomplete="new-password">
            </div>
          </div>

          <div class="mb-3">
            <input type="checkbox" class="form-check-input" id="terms" v-model="terms">
            <label class="form-check-label" for="terms">I accept the <a href="/terms">Terms and Conditions</a></label>
<!--            <label class="form-check-label" for="terms">I accept the <button @click.prevent="readTerms">Terms and Conditions</button></label>-->

            <span class="text-danger" v-if="errors.terms">
                  <span>{{ errors.terms[0] }}</span>
              </span>
          </div>

          <div class="mb-5">
            <button type="submit" class="btn btn-primary">Register</button>
          </div>

          <p>
            Already have an account? <a href="/login" @click.prevent="$router.push('/login')">Login</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Registration",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      terms: false,
      errors: [],
    }
  },

  methods: {
    readTerms() {
      window.open('/terms')
    },

    registration() {
      return new Promise((res, rej) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios.post('/register', {
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirm,
            terms: this.convertTerms
          }).then(response => {
            localStorage.setItem('token', response.config.headers['X-XSRF-TOKEN']);
            this.$router.push('/');
          }).catch(error => {
            console.log(this.convertTerms)
            this.errors = error.response.data.errors
            console.log('err', this.errors)
          });
        });
      })
    },
  },
  computed: {
    convertTerms() {
      if (this.terms) {
        return 1;
      } else {
        return ''
      }
    }
  }
}
</script>

<style>
.container, .row {
  height: 100%;
}

body {
  background-color: var(--accent-color-regular);
}

.form form {
  background-color: var(--white);
  padding: 40px 50px 30px 50px;
  box-shadow: 0 8px 20px rgb(0 0 0 / 20%);
}

.background-left {
  background: url("../../img/back.png") no-repeat center center;
  background-size: contain;
}

.app-name {
  font-size: 42px;
  text-align: center;
  color: var(--accent-color-regular);
  font-weight: bold;
}

.form form h1 {
  font-size: 1.75rem;
  text-align: center;
}
</style>
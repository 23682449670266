import Login from "../pages/Login";
import Registration from "../pages/Registration";
import Layouts from "../pages/Layouts";
import Layout from "../pages/Layout";
import Profile from "../pages/Profile";
import Test from "../pages/Test";
import Media from "../pages/Media";
import Terms from "../pages/Terms";

const routes = [
  {
    path:"/",
    name: "Layouts",
    component: Layouts,
    alias: ['/index.html', '/home', '/layouts'],
    meta: { requiresAuth: true }
  },
  {
    path:"/login",
    name: "Login",
    component: Login
  },
  {
    path:"/register",
    name: "Registration",
    component: Registration
  },
  // {
  //     path:"/terms",
  //     name: "Terms",
  //     component: Terms
  // },
  {
    path:"/layouts/:id",
    name: "Layout",
    component: Layout
  },
  {
    path:"/profile",
    name: "Profile",
    component: Profile
  },
  {
    path:"/create",
    name: "Create",
    component: Layout,
    meta: { requiresAuth: true }
  },
  {
    path:"/edit/:id",
    name: "Edit",
    component: Layout,
    meta: { requiresAuth: true }
  },
  {
    path:"/media/:mediaType?/:mediaId?",
    name: "Media",
    component: Media,
    meta: { requiresAuth: true }
  },
  {
    path:"/test",
    name: "Test",
    component: Test
  }
  ];

export default routes
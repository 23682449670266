<template>
    <VueDragResize
        :tabindex="index"
        :parentLimitation="true"
        :contentClass="'media ' + item.type"
        :w="layout.media[index].width ?? layout.media[index].minWidth"
        :h="layout.media[index].height ?? layout.media[index].minHeight"
        :x="layout.media[index].left"
        :y="layout.media[index].top"
        :aspectRatio="layout.media[index].lockAspect"
        :z="layout.media[index].zIndex"
        @resizing="Resize"
        @dragging="Resize"
        @clicked="SelectMedia(index)"
        @deactivated="SelectMedia(null)"
        ref="media">

        <img v-if="item.type === 'image'" :src="item.src"/>

        <video v-if="item.type === 'video'" :src="item.src" :autoplay="item.autoplay" :loop="item.loop" muted></video>

        <p v-if="item.type === 'text'"
           :style="{ color: item.color, fontSize: item.fontSize + 'px', fontFamily: item.fontFamily, textAlign: item.alignment }">
            {{ item.text }}</p>

        <p :class="'marquee-' + item.direction" v-if="item.type === 'ticker'"
           :style="{
      color: item.color,
      fontSize: item.fontSize + 'px',
      fontFamily: item.fontFamily,
      animationDuration: item.duration + 's',
    }" :ref="item.direction">
            {{ item.text }}
        </p>

        <iframe v-if="item.type === 'web'" :src="item.src"
                :width="layout.media[index].width ?? layout.media[index].minWidth"
                :height="layout.media[index].height ?? layout.media[index].minHeight" frameborder="0"
                allowfullscreen></iframe>

        <div v-if="item.type === 'zmanim'" class="zmanim-item"
             :style="{ color: item.color, fontSize: item.fontSize + 'px', lineHeight: item.lineHeight + 'px', padding: item.padding + 'px', fontFamily: item.fontFamily }">

            <div class="zmanim-item-title">
                <div><span v-if="item.city">{{ item.city }}, </span><span
                    v-if="item.state">{{ item.state }}, </span><span v-if="item.country">{{ item.country }}</span></div>
                <div><span class="zmanim-item-date">{{ item.date }}</span><span>{{layout.time}}</span></div>
            </div>
            <table class="zmanim-table">
                <tbody>
                <tr v-for="(data, idx) in item.zmanimData" :class="[ idx % 2 === 1 ? 'light' : 'dark']">
                    <td v-if="item.lang === 'english' || item.lang === 'englishAndHebrew'">{{ data.titleEnglish }}</td>
                    <td>{{ data.date }}</td>
                    <td v-if="item.lang === 'hebrew'|| item.lang === 'englishAndHebrew'" class="title-hebrew">{{
                            data.titleHebrew
                        }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </VueDragResize>
</template>

<script>
import VueDragResize from 'vue-drag-resize';

export default {
    name: "Media",
    components: {VueDragResize},
    inject: ["layout"],
    props: ["index", "item", "parentRect", "selectedMedia", "SelectMedia"],
    // inject: ["layout", "SelectMedia", "selectedMedia"],
    // props: ["index", "item", "parentRect"],
    data() {
        return {
            isActive: false,
        }
    },

    watch: {
        'item.direction': {
            deep: true,
            handler() {
                setTimeout(() => {
                    this.setPaddingInVerticalTicker()
                }, 100)

            }
        }
    },

    mounted() {
        this.setPaddingInVerticalTicker()
    },

    methods: {
        setPaddingInVerticalTicker() {
            if (this.item.direction === 'up') {
                let height = this.$refs.media.height
                this.$refs.up.style.paddingTop = height + 'px'
            }
            if (this.item.direction === 'down') {
                let height = this.$refs.media.height
                this.$refs.down.style.paddingTop = height + 'px'
            }
        },
        Resize: function (newRect) {
            this.setPaddingInVerticalTicker()
            let parentRect = this.parentRect();
            this.layout.media[this.index].left = newRect.left;
            this.layout.media[this.index].top = newRect.top;

            this.layout.media[this.index].width = newRect.width;
            this.layout.media[this.index].height = newRect.height;

            this.layout.media[this.index].percentWidth = newRect.width * 100 / parentRect.width;
            this.layout.media[this.index].percentHeight = newRect.height * 100 / parentRect.height;
            this.layout.media[this.index].percentLeft = newRect.left * 100 / parentRect.width;
            this.layout.media[this.index].percentTop = newRect.top * 100 / parentRect.height;
        },

        SelectCurrentMedia: function (ev) {
            this.$nextTick(() => {
                if (ev) this.$refs.media.focus();
            });
        },

    }
}
</script>

<style scoped>
.media {
    position: absolute;
    border-style: dashed;
    border-width: 4px;
    border-color: transparent;
    z-index: 10;
}

.media:hover {
    border-color: var(--accent-color-regular);
}

.media.active {
    border-color: var(--accent-color-light);
}

.media img, .media video {
    width: 100%;
    height: 100%;
    background-color: #000;
}

.media.ticker {
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    /*padding: 0;*/
}

.marquee-up {
    animation-name: marquee-up;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    display: inline-block;
}

@keyframes marquee-up {
    0% {
        transform: translate(0, 0%);
    }

    100% {
        transform: translate(0, -100%);
    }
}

.marquee-down {
    animation-name: marquee-down;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    display: inline-block;
}

@keyframes marquee-down {
    0% {
        transform: translate(0, -100%);
    }

    100% {
        transform: translate(0, 0);
    }
}

.marquee-left {
    animation-name: marquee-left;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    padding-left: 100%;
    white-space: nowrap;
    display: inline-block;
}

@keyframes marquee-left {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

.marquee-right {
    animation-name: marquee-right;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    padding-left: 100%;
    white-space: nowrap;
    display: inline-block;
}

@keyframes marquee-right {
    0% {
        transform: translate(-100%, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

.resize {
    position: absolute;
    display: none;
    cursor: w-resize;
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: #fff;
    pointer-events: all !important;
    z-index: 20;
}

.media.active .resize {
    display: block;
}

.resize.e {
    inset: 50% -7px auto auto;
    transform: translateY(-50%);
    cursor: e-resize;
}

.resize.w {
    inset: 50% auto auto -7px;
    transform: translateY(-50%);
    cursor: w-resize;
}

.resize.n {
    inset: -7px auto auto 50%;
    transform: translateX(-50%);
    cursor: n-resize;
}

.resize.s {
    inset: auto auto -7px 50%;
    transform: translateX(-50%);
    cursor: s-resize;
}

.resize.ne {
    inset: -7px -7px auto auto;
    transform: none;
    cursor: ne-resize;
}

.resize.nw {
    inset: -7px auto auto -7px;
    transform: none;
    cursor: nw-resize;
}

.resize.se {
    inset: auto -7px -7px auto;
    transform: none;
    cursor: se-resize;
}

.resize.sw {
    inset: auto auto -7px -7px;
    transform: none;
    cursor: sw-resize;
}

.zmanim-item {
    background: #ffffff;
    padding: 10px;
}

.zmanim-item-title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.zmanim-item-date {
    margin-right: 5px;
}

.zmanim-table {
    padding: 0 5px;
    width: 100%;
}

.zmanim-table .light {
    background: #fff;
}

.zmanim-table .dark {
    background: #F2F2F2;
}

.title-hebrew {
    text-align: right;
}


</style>

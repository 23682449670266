require("./bootstrap");
window.Vue = require("vue").default;
import { createApp } from "vue";
import App from './App.vue';
import router from "./router/index";

// const app = createApp(App);
const app = createApp({
  components:{
    'app': App,
  }});

// app.config.unwrapInjectedRef = true;

// const cors=require("cors");
// const corsOptions ={
//   origin:'*',
//   credentials:true,            //access-control-allow-credentials:true
//   optionSuccessStatus:200,
// }
//
// app.use(cors(corsOptions))

app.use(router).mount("#app");
<template>
  <OutputMedia
    title="Select image"
    typeFile="image"
    :callback="CreateImage"
    ref="createImage"/>
  <OutputMedia
    title="Select video"
    typeFile="video"
    :callback="CreateVideo"
    ref="createVideo"/>

  <OutputMedia
    title="Insert Text"
    typeFile="text"
    :callback="CreateText"
    ref="createText"/>

  <OutputMedia
    title="Insert Ticker"
    typeFile="ticker"
    :callback="CreateTicker"
    ref="createTicker"/>

  <OutputMedia
    title="Insert Link"
    typeFile="web"
    :callback="CreateWebPage"
    ref="createWebPage"/>

  <!--  <CreateWebPage-->
  <!--    title="Select Web Page"-->
  <!--    :callback="CreateWebPage"-->
  <!--    ref="CreateWebPage"/>-->

  <CreateZmanim
    title="Select Options"
    :callback="CreateZmanim"
    ref="CreateZmanim"/>

  <div :class="[ 'editor-menu', 'editor-media', { open: isOpen } ]">

    <h6>Media</h6>
    <hr class="dropdown-divider">
    <a href="#" class="media-image editor-media-item" @click.prevent="this.$refs.createImage.Open">
      <i class="bi bi-image"></i>
    </a>
    <a href="#" class="media-video editor-media-item" @click.prevent="this.$refs.createVideo.Open">
      <i class="bi bi-film"></i>
    </a>
    <a href="#" class="media-text editor-media-item" @click.prevent="this.$refs.createText.Open">
      <i class="bi bi-fonts"></i>
    </a>
    <a href="#" class="media-ticker editor-media-item" @click.prevent="this.$refs.createTicker.Open">
      <i class="bi bi-textarea-t"></i>
    </a>
    <a href="#" class="media-webpage editor-media-item" @click.prevent="this.$refs.createWebPage.Open">
      <i class="bi bi-globe"></i>
    </a>
    <a href="#" class="media-webpage editor-media-item" @click.prevent="this.$refs.CreateZmanim.Open">
      <i class="bi bi-brightness-alt-high-fill"></i>
    </a>
    <a href="#" class="menu-open-button" @click.prevent="Open()">
      <i class="bi bi-arrow-left-right"></i>
    </a>
  </div>
</template>

<script>
import OutputMedia from "./Modal.OutputMedia.vue";
import CreateWebPage from "./Modal.CreateWebPage.vue";
import CreateZmanim from "./Modal.CreateZmanim.vue";

export default {
  name: "AddMedia",
  components: {OutputMedia, CreateWebPage, CreateZmanim},
  inject: ["layout"],
  props: ["isLayoutLoad"],
  data() {
    return {
      isOpen: false,
    }
  },
  mounted() {
    // window.addEventListener('resize', this.updateWidth);
  },
  watch: {
    'layout.orientation': {
      deep: true,
      handler() {
        this.recalculationOfInterest()
      }
    },

    'layout.scale': {
      deep: true,
      handler() {
        this.recalculationOfInterest()
      }
    }
  },
  methods: {
    // updateWidth() {
    //   console.log(this.layout.width)
    //   this.recalculationOfInterest()
    // },

    recalculationOfInterest() {
      this.layout.media.forEach(m => {
        if (m.height !== null) {
          m.percentHeight = m.height / this.layout.height * 100;
        } else {
          m.percentHeight = m.minHeight / this.layout.height * 100;
        }
        if (m.width !== null) {
          m.percentWidth = m.width / this.layout.width * 100;
        } else {
          m.percentWidth = m.minWidth / this.layout.width * 100;
        }

        m.percentLeft = m.left / this.layout.width * 100;
        m.percentTop = m.top / this.layout.height * 100
      })
    },

    Open: function () {
      this.isOpen = !this.isOpen;
      return this.isOpen;
    },

    GetMediaTemplate: function () {
      return {
        minWidth: 150,
        minHeight: 100,
        percentWidth: 150 / this.layout.width * 100,
        percentHeight: 100 / this.layout.height * 100,
        percentLeft: 20 / this.layout.width * 100,
        percentTop: 20 / this.layout.height * 100,
        top: 20,
        left: 20,
        width: null,
        height: null,
        zIndex: 0
      }
    },

    CreateId: function () {
      return Math.floor(100000000 + Math.random() * 900000000);
    },

    CreateImage: function (media) {
      this.layout.media.push(
        Object.assign(this.GetMediaTemplate(), {
          id: this.CreateId(),
          type: "image",
          src: media.url
        }));
      this.isOpen = false;
    },

    CreateVideo: function (media) {
      this.layout.media.push(Object.assign(this.GetMediaTemplate(), {
        id: this.CreateId(),
        type: "video",
        src: media.url,
        duration: 5,
        autoplay: true,
        loop: true
      }));
      this.isOpen = false;
    },

    CreateTicker: function (media) {
      this.layout.media.push(Object.assign(this.GetMediaTemplate(), {
        id: this.CreateId(),
        type: "ticker",
        text: media.ticker,
        duration: 5,
        direction: 'right',
        fontSize: 16,
        fontFamily: "Segoe UI",
        color: "#000000"
      }));
      this.isOpen = false;
    },

    CreateText: function (media) {
      this.layout.media.push(Object.assign(this.GetMediaTemplate(), {
        id: this.CreateId(),
        type: "text",
        text: media.text,
        fontSize: 16,
        fontFamily: "Segoe UI",
        color: "#000000",
        alignment: "left"
      }));
      this.isOpen = false;
    },

    CreateWebPage: function (media) {
      this.layout.media.push(Object.assign(this.GetMediaTemplate(), {
        id: this.CreateId(),
        type: "web",
        src: media.web,
      }));
      // this.$refs.CreateWebPage.Open = !this.$refs.CreateWebPage.Open
      this.isOpen = false;
      // console.log(this.$refs.CreateWebPage.Open)
    },

    CreateZmanim: function (media) {
        console.log(media.zmanimData)
      this.layout.media.push(Object.assign(this.GetMediaTemplate(), {
        id: this.CreateId(),
        type: "zmanim",
        date: media.date,
        lang: media.lang,
        daySelection: media.daySelection,
        latitude: media.latitude,
        longitude: media.longitude,
        postalCode: media.postalCode,
        selectedTimeZoneUtc: media.selectedTimeZoneUtc,
        city: media.city,
        state: media.state,
        country: media.country,
        zmanimData: media.zmanimData,
        // src: media,
        fontSize: 12,
        fontFamily: "Segoe UI",
        lineHeight: 20,
        padding: 10,
        color: "#000000",
        width: 360,
        height: 300,
        percentWidth: 360 / this.layout.width * 100,
        percentHeight: 300 / this.layout.height * 100,
      }));
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>
/* -------------------editor-media-------------------- */

.editor-menu {
  width: auto;
}

.editor-media {
  left: 0;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
}

.editor-media.open {
  transform: translateX(0%);
}

.editor-media .menu-open-button {
  right: -20px;
}

.editor-media-item {
  padding: 30px 35px;
}

.editor-media-item:hover {
  background-color: var(--gray-light);
}

.editor-media-item i:before {
  font-size: 36px;
}

/* -------------------editor-media-------------------- */
</style>

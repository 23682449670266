<template>
    <div class="container">
        <Header/>
        <main>
            <div class="row g-0 editor align-items-center">
                <div class="row g-0 editor-header">
                    <div class="col-5 align-self-center">
                        <h5>Layout: <span class="layout-name">{{ layout.name }}</span></h5>
                    </div>
                    <TopPanel @show-add-media="showAddMedia"
                              @show-edit-media="showEditMedia"
                              :callback="SaveLayout"
                              :layout="layout"
                              :selectedMedia="selectedMedia"
                    />
                </div>

                <div class="editor-container" ref="editorContainer">

                    <OutputMedia
                        title="Select media"
                        ref="outputMedia"/>

                    <AddMediaMenu :isLayoutLoad="isLayoutLoad" ref="AddMediaMenu"/>

                    <EditMediaMenu :layout="layout" :isLayoutLoad="isLayoutLoad" :selectedMedia="selectedMedia"
                                   ref="EditMediaMenu"/>

                    <LayoutWindow
                        v-if="isLayoutLoad"
                        :media="layout.media"
                        :key="layout.width"
                        :SelectMedia="SelectMedia"
                        :selectedMedia="selectedMedia"
                        ref="layoutWindow"/>

                    <Loader v-else/>

                    <EditLayout
                        :isLayoutLoad="isLayoutLoad"
                        :errors="errors"
                        @save-layout="SaveLayout"
                        @select-orientation="SetLayoutWindowSize"
                        :layout="layout"
                        ref="EditLayout"/>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import {computed} from 'vue';
import Header from "../components/Header.vue";
import Loader from "../components/Loader.vue";
import LayoutWindow from "../components/LayoutWindow.vue";
import TopPanel from "../components/TopPanel.vue";
import EditLayout from "../components/EditLayout.vue";
import EditMediaMenu from "../components/EditMediaMenu.vue";
import AddMediaMenu from "../components/AddMediaMenu.vue";
import OutputMedia from "../components/Modal.OutputMedia.vue";

export default {
    name: "Layout",
    components: {Header, Loader, LayoutWindow, TopPanel, EditLayout, EditMediaMenu, AddMediaMenu, OutputMedia},
    props: ['user'],
    data() {
        return {
            layout: {
                id: this.$route.params.id ?? null,
                name: "",
                backgroundType: "color",
                backgroundColor: "#00A480",
                backgroundImage: "",
                backgroundSize: null,
                orientation: "landscape",
                media: [],
                width: 0,
                height: 0,
                scale: localStorage.getItem("LayoutScale") ?? 100,
                time: null,
            },
            isLayoutLoad: false,
            selectedMedia: null,
            errors: [],
        }
    },

    provide() {
        return {
            layout: this.layout,
            SelectMedia: this.SelectMedia,
            RemoveMedia: this.RemoveMedia,
            AddMedia: this.AddMedia
        }
    },

    watch: {
        'layout.scale': {
            handler() {
                this.SetLayoutWindowSize()
            }
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.SetLayoutWindowSize();
        });

        if (this.layout.id != null) {
            this.GetLayout();
        } else {
            this.isLayoutLoad = true;
        }

        this.SetLayoutWindowSize();

        setInterval(() => {
            this.getTime()
        }, 1000)
    },

    methods: {
        getTime() {
            let options = {
                hour: 'numeric',
                minute: 'numeric',
            };

            this.layout.time = new Date().toLocaleString('en-US', options);
        },
        SetLayoutWindowSize: function () {
            if (this.layout.orientation === "landscape") {
                this.layout.width = (this.$refs.editorContainer.clientWidth * 80 / 100) * (this.layout.scale / 100);
                this.layout.height = this.layout.width / (16 / 9);
            } else if (this.layout.orientation === "portrait") {
                this.layout.width = (this.$refs.editorContainer.clientWidth * 45 / 100) * (this.layout.scale / 100);
                this.layout.height = this.layout.width * (16 / 9);
            }
        },

        GetLayout: function () {
            this.isLayoutLoad = false;
            axios.get('/api/layouts/' + this.layout.id).then(response => {
                let data = {
                    backgroundColor: response.data.background_color,
                    backgroundImage: response.data.background_image,
                    backgroundSize: response.data.background_size,
                    backgroundType: response.data.background_type,
                    name: response.data.name,
                    media: response.data.media
                }
                this.layout = Object.assign(this.layout, data);
                this.isLayoutLoad = true;
                this.SetLayoutWindowSize();
            }).catch(error => {
                console.log(error.response);
            });
        },

        SaveLayout: function (openPreview = false) {
            if (this.layout.id == null) {
                this.isLayoutLoad = false;
                axios.post('/api/layouts/create/', {
                    name: this.layout.name,
                    orientation: this.layout.orientation,
                    background_type: this.layout.backgroundType,
                    background_color: this.layout.backgroundColor,
                    background_image: this.layout.backgroundImage,
                    background_size: this.layout.backgroundSize,
                    width: this.layout.width,
                    height: this.layout.height,
                    media: this.layout.media

                }).then(response => {
                    let data = {
                        name: response.data.name,
                        orientation: response.data.orientation,
                        backgroundType: response.data.background_type,
                        backgroundColor: response.data.background_color,
                        backgroundImage: response.data.background_image,
                        backgroundSize: response.data.background_size,
                        width: response.data.width,
                        height: response.data.height,
                        media: response.data.media,
                        id: response.data.id
                    }
                    this.layout = Object.assign(this.layout, data);
                    // this.$router.replace({name: "Edit", params: {id: response.data.id}});
                    this.isLayoutLoad = true;
                    if (this.$refs.EditLayout.Open()) {
                        this.$refs.EditLayout.Open()
                    }
                    if (openPreview) {
                        window.open('/preview/' + this.layout.id);
                    }
                    this.errors = []
                }).catch(error => {
                    this.isLayoutLoad = true;
                    this.errors = error.response.data.errors
                    console.log(error.response);
                    if (!this.$refs.EditLayout.Open()) {
                        this.$refs.EditLayout.Open()
                    }
                });
            } else {
                this.isLayoutLoad = false;
                axios.post(`/api/layouts/update/${this.layout.id}`, {
                    name: this.layout.name,
                    orientation: this.layout.orientation,
                    background_type: this.layout.backgroundType,
                    background_color: this.layout.backgroundColor,
                    background_image: this.layout.backgroundImage,
                    background_size: this.layout.backgroundSize,
                    width: this.layout.width,
                    height: this.layout.height,
                    media: this.layout.media
                }, {
                    headers: {}
                }).then(response => {
                    this.isLayoutLoad = true;
                    if (this.$refs.EditLayout.Open()) {
                        this.$refs.EditLayout.Open()
                    }
                    if (openPreview) {
                        window.open('/preview/' + this.layout.id);
                    }
                    this.errors = []
                }).catch(error => {
                    this.errors = error.response.data.errors
                    console.log(error.response);
                    if (!this.$refs.EditLayout.Open()) {
                        this.$refs.EditLayout.Open()
                    }
                });
            }
        },

        RemoveLayout: function () {
        },

        SelectOrientation: function (value) {
            this.layout.orientation = value;
            this.SetLayoutWindowSize();
        },

        SelectMedia: function (mediaIndex) {
            this.selectedMedia = mediaIndex;
        },

        AddMedia: function (media) {
            this.layout.media.push(media);
        },

        RemoveMedia: function (mediaIndex = null) {
            this.selectedMedia = null;
            if (mediaIndex != null) this.layout.media.splice(mediaIndex, 1);
        },

        showEditMedia() {
            this.$refs.EditMediaMenu.Open()
            if (this.$refs.AddMediaMenu.Open()) {
                !this.$refs.AddMediaMenu.Open()
            }
        },

        showAddMedia() {
            this.$refs.AddMediaMenu.Open()
            if (this.$refs.EditMediaMenu.Open()) {
                !this.$refs.EditMediaMenu.Open()
            }
        }
    }
}
</script>

<style>
main, .container-md, .editor, .editor-container {
    width: 100%;
    height: 100%;
}

.editor-header {
    background-color: #fff;
    padding: 15px 35px;
    height: unset;
}

.editor-container {
    position: relative;
    display: flex;
    justify-content: center;
    /*overflow: hidden;*/
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.editor-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.menu-open-button {
    position: absolute;
    top: 30px;
    padding: 6px 10px;
    border: 3px solid #fff;
    background-color: #fff;
    border-radius: 50%;
    color: var(--gray);
    z-index: 100;
}

.editor-menu {
    position: absolute;
    top: 0;
    width: 300px;
    /*height: 100%;*/
    background-color: #fff;
    padding: 35px;
    transition: all 0.3s;
    z-index: 100;
}

.slide-fade-enter-active {
    transition: all 0.4s cubic-bezier(0, 0.6, 0, 0.9);
}

.slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(0, 0.6, 0, 0.9);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}
</style>
